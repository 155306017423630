<template>
    <div v-loading.fullscreen.lock="loading">
        <petty-search-bar-component
            add_auth="新增备用金申请"
            select_pay_auth="备用金批量汇款"
            @pay_select="pay_select"
            @search="get_petty_index"
            @addTeam="addTeam"
        ></petty-search-bar-component>
        <common-table-component
            details_auth="备用金申请详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_petty"
            :duoxuan="true"
            @selects="selects"
            table_height="549px"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_petty_index"
        ></common-page-component>
        <petty-edit-component
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :petty_details_data="petty_details_data"
            :enterprise_department="enterprise_department"
            :edit_auth="edit_auth"
            :del_auth="del_auth"
            :add_file_auth="add_file_auth"
            :to_approval_auth="to_approval_auth"
            :sel_approval_auth="sel_approval_auth"
            :ApprovalAuth="ApprovalAuth"
            :withdraw_auth="withdraw_auth"
            :pay_auth="pay_auth"
            print_auth="打印备用金申请"
            :budget_count="budget_count"
            @show_edit="show_edit"
            @addTeam="addTeam"
            @search="get_petty_index"
            @exitPettyDialog="dialogExit"
            @frefresh="frefresh"
            @details_row="details_petty"
        ></petty-edit-component>
    </div>
</template>

<script>
import { enterprise_department_request} from '@/network/enterprise.js'
import { petty_index_request,petty_details_request,petty_select_pay_request} from '@/network/finance/petty.js'
import { thousandBitSeparator,fomatFloat} from '@/assets/js/common.js'
import { budget_count_request } from '@/network/finance/BudgetUse.js'

import PettySearchBarComponent from '@/components/finance/petty/PettySearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import PettyEditComponent from '@/components/finance/petty/PettyEditComponent'

export default {
    name:'',
    data(){
        return {
            fid: 0,
            edit_type: 0,
            loading: false,
            fdialogFormVisible: false,
            cond: {
                name: '',
                type: '',
                state: '',
                pay_state: '',
                use_state: '',
                enterprise_id: '',
                department_id: '',
                staff_id: '',
                title_content: '',
                page: 1,
                limit: 10,
            },
            ftotal: 0,
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'apply_date',
                    label: '申请日期',
                    minWidth: '100px',
                },
                {
                    prop: 'name',
                    label: '备用金单号',
                    minWidth: '120px'
                },
                {
                    prop: 'type',
                    label: '备用金类型',
                    minWidth: '120px',
                    html: (row) => {
                        if(row['type']===0) {
                            return `<div class="cell"><span class="el-tag el-tag el-tag--plain">提前借支</span></div>`
                        } else if(row['type']===1) {
                            return `<div class="cell"><span class="el-tag el-tag--warning el-tag--plain">储备额度</span></div>`
                        } else {
                            return `<div class="cell"><span class="el-tag el-tag--success el-tag--plain">保证金</span></div>`
                        }
                    }
                },
                {
                    prop: 'state',
                    label: '审批状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待提交'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '审批中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已通过'
                            }
                        } else if(d===3) {
                            return {
                                type: 'danger',
                                filter_data: '已驳回'
                            }
                        }
                    },
                },
                {
                    prop: 'pay_state',
                    label: '汇款状态',
                    minWidth: '90px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待汇款'
                            }
                        } else {
                            return {
                                type: 'success',
                                filter_data: '已汇款'
                            }
                        }
                    }
                },
                {
                    prop: 'use_state',
                    label: '使用情况',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'danger',
                                filter_data: '未用完'
                            }
                        } else if(d===1) {
                            return {
                                type: 'success',
                                filter_data: '已用完'
                            }
                        }
                    }
                },
                {
                    prop: 'title',
                    label: '标题',
                    minWidth: '300px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'money',
                    label: '申请金额(元)',
                    minWidth: '120px',
                    editdata(row) {
                        return thousandBitSeparator(fomatFloat(row['money']))
                    }
                },
                {
                    prop: 'used_money',
                    label: '剩余金额(元)',
                    minWidth: '120px',
                    align: 'right',
                    html: (row) => {
                        return `<span style="color: #2828FF;">` + thousandBitSeparator(fomatFloat(row['money'] - row['used_money'])) + `</span>`
                    }
                },
                {
                    prop: 'current_examine_staff_name',
                    label: '当前审批人',
                    minWidth: '100px',
                },
                {
                    prop: 'staff_name',
                    label: '申请人',
                    minWidth: '100px',
                },
                {
                    prop: 'department_name',
                    label: '申请部门',
                    minWidth: '120px'
                },
                {
                    prop: 'enterprise_name',
                    label: '申请企业',
                    minWidth: '260px'
                }
            ],
            petty_details_data: {},
            enterprise_department: [],
            edit_auth: false,
            del_auth: false,
            //补充附件
            add_file_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //汇款
            pay_auth: false,
            //撤回审批
            withdraw_auth: false,
            select_id: [],
            budget_count: {
                all_quota: 0,
                use_quota: 0,
                per_quota: 0,
                entertain_quota: 0,
                travel_quota: 0,
                other_quota: 0
            }
        }
    },
    computed:{},
    methods:{
        get_petty_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.type = param.type ?? this.cond.type
            this.cond.state = param.state ?? this.cond.state
            this.cond.pay_state = param.pay_state ?? this.cond.pay_state
            this.cond.use_state = param.use_state ?? this.cond.use_state
            this.cond.enterprise_id = param.enterprise_id ?? this.cond.enterprise_id
            this.cond.department_id = param.department_id ?? this.cond.department_id
            this.cond.staff_id = param.staff_id ?? this.cond.staff_id
            this.cond.title_content = param.title_content ?? this.cond.title_content
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            petty_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_petty(id) {
            this.fid = id
            this.fdialogFormVisible = true
            petty_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.petty_details_data = s.result
                        this.petty_details_data.department_id = [s.result.enterprise_id,s.result.department_id]
                        //权限判断
                        this.is_auth(s.result)
                        //获取预算
                        const department_id = this.petty_details_data.department_id[1]
                        const year = this.petty_details_data.apply_date ? this.petty_details_data.apply_date.substr(0,4) : 0
                        this.get_budget_count(department_id ,year)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
            this.budget_count ={
                all_quota: 0,
                use_quota: 0,
                per_quota: 0,
                entertain_quota: 0,
                travel_quota: 0,
                other_quota: 0
            }
        },
        show_edit() {
            this.edit_type = 2
            this.budget_count ={
                all_quota: 0,
                use_quota: 0,
                per_quota: 0,
                entertain_quota: 0,
                travel_quota: 0,
                other_quota: 0
            }
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
            this.budget_count ={
                all_quota: 0,
                use_quota: 0,
                per_quota: 0,
                entertain_quota: 0,
                travel_quota: 0,
                other_quota: 0
            }
        },
        get_enterprise_department() {
            this.loading = true
            enterprise_department_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.enterprise_department = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        frefresh() {
            this.details_petty(this.fid)
            this.get_petty_index()
        },
        is_auth(res) {
            this.edit_auth = false
            this.del_auth = false
            this.add_file_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.pay_auth = false
            this.withdraw_auth = false
            
            if(res.state === 0) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交备用金申请')
                    this.edit_auth = this.$_has('修改备用金申请')
                    this.del_auth = this.$_has('删除备用金申请')
                }
            } else if(res.state === 1) {
                 //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.add_file_auth = this.$_has('补充备用金申请附件')
                    this.withdraw_auth = this.$_has('撤回备用金申请')
                }
                //判断当前审批人是否为自己
                if(res.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批备用金申请')
                }
                this.sel_approval_auth = this.$_has('查看备用金申请审批记录')
            } else if(res.state === 2) {
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.add_file_auth = this.$_has('补充备用金申请附件')
                }
                this.sel_approval_auth = this.$_has('查看备用金申请审批记录')
                this.pay_auth = this.$_has('备用金汇款')
            } else if(res.state === 3) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交备用金申请')
                    this.edit_auth = this.$_has('修改备用金申请')
                    this.del_auth = this.$_has('删除备用金申请')
                }
                this.sel_approval_auth = this.$_has('查看备用金申请审批记录')
            }
        },
        selects(rows) {
            this.select_id = []
            rows.forEach(v => {
                this.select_id.push(v.id)
            })
        },
        pay_select() {
            if(this.select_id.length === 0) {
                this.$message.error('请先选择需要汇款的备用金')
                return
            }

            this.loading = true
            petty_select_pay_request(this.select_id)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.$message.success('批量汇款成功')
                        this.get_petty_index()
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_budget_count(department_id,year) {
            budget_count_request({ department_id:department_id, year:year })
                .then((s) => {
                    this.budget_count = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_enterprise_department()
        this.get_petty_index()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        PettySearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        PettyEditComponent
    },
    watch:{}
}
</script>

<style lang='less'></style>